<template>
  <div class="PTContainer">
    <div style="overflow-y: scroll;height:100%;width:100%" ref="GoTopContent">
      <Title></Title>
      <div class="banner">
        <h4 style="font-size: 46px; font-weight: 600; padding-bottom:40px">
          低代码开发平台
        </h4>
        <button @click="$router.push('/freeTrial')">立即预约演示</button>
      </div>

      <div class="ptDesc center">
        <div class="ptDescContent">
          <div class="ptDescLeft">
            <img src="@/assets/product/productPt/ptDecs.png" alt="" style="width:450px;height:100%;border-radius: 10px 0 0 10px;">
            <div class="ptDescLeftMask"></div>
          </div>
          <div class="ptDescRigth">
            易普行低代码应用开发平台（aPaaS）采用低代码/无代码开发模式、简单易用的数据建模、灵活可视的流程编排、丰富的UI组件库、拖拽式页面构建、多终端适配，快速地为用户搭建业务系统。在适应智慧人事应用建设的同时，能够敏捷响应用户个性化系统的建设需求，在同行中具有明显的技术先进性。
          </div>
        </div>

        <h4 class="descH4">低代码开发平台的价值优势</h4>
        <span class="line"></span>
        <div class="valueContent">
          <div v-for="(item,index) in valueList" :key="index" class="valueItem">
            <div class="valueItemMask"></div>
            <img :src="item.src" alt="" class="valueLeftRed">
            <img :src="item.hoverSrc" alt="" class="valueLeftHover">
            <div class="valueRight">
              <div class="valueTitle">
                {{item.title}}
              </div>
              <div class="valueText">
                {{item.content}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mainAble center">
        <h4 class="descH4">主要功能</h4>
        <span class="line"></span>
        <img src="@/assets/product/productPt/mingn.png" alt="" style="width:1200px;padding-top:60px">
      </div>
      <Foot></Foot>
    </div>

    <BackTop class="backTop"></BackTop>
  </div>
</template>

<script>
export default {
  name: 'ProductPt',
  data () {
    return {
      valueList: [
        { title: '快速构建复杂业务应用能力', content: '零代码可视化配置，低代码个性化开发', src: require('@/assets/Cooperation/ecological/open.png'), hoverSrc: require('@/assets/product/productPt/jgzx.png') },
        { title: '智慧人事一体化建设核心基础能力', content: '数据中台、统一身份认证、服务大厅、移动化、国际化', src: require('@/assets/product/productPt/agile.png'), hoverSrc: require('@/assets/product/productPt/mjbh.png') },
        { title: '开发运维一体化管理能力', content: '提供集开发、测试、上线、运维、运营一体化管理与服务', src: require('@/assets/Cooperation/Partnership/PlatformSupport.png'), hoverSrc: require('@/assets/product/productPt/kfyw.png') },
        { title: '等保三级安全可信赖能力', content: '符合国家等级保护三级测评要求', src: require('@/assets/sericeAndSupport/safeguardSystem.png'), hoverSrc: require('@/assets/product/productPt/saft.png') }
      ],
      mainFunction: [
        {
          title: '数据模型',
          fontLeft: true,
          p: [
            {
              tab: '业务建模：',
              desc: '根据业务需求构建业务模型，元数据驱动，可灵活定义数据结构、数据关系、校验条件和代码标准等'
            },
            {
              tab: '业务接口：',
              desc: '平台自动为业务模型提供常用数据操作接口，包括新增、编辑、删除、查询、导入、导出、申请、审核等，并能根据需要灵活扩展和自定义'
            },
            {
              tab: '授权操作',
              desc: '业务模型操作授权，可灵活设置接口范围，精确到行和列'
            }
          ],
          src: require('@/assets/product/productPt/datamodel.png')
        },
        {
          title: '工作流引擎',
          fontLeft: false,
          p: [
            {
              desc: '1、灵活定义业务流程，支持并行、串行流程，可灵活设置审批人和抄送人，支持或签、会签和顺签等审批方式'
            },
            { desc: '2、可查看和办理待办事项、跟踪自己发起的业务申请的办理进度' },
            { desc: '3、实施监控统计流程运行情况，分析流程运行效率' }
          ],
          src: require('@/assets/product/productPt/gongzuoliu.png')
        },
        {
          title: '页面引擎',
          fontLeft: true,
          p: [
            {
              desc: '提供丰富的组件库，可像搭积木一样组装页面'
            }
          ],
          src: require('@/assets/product/productPt/pageyingqing.png')
        },
        {
          title: '消息中心',
          fontLeft: false,
          p: [
            {
              desc: '统一的发送接口'
            },
            { desc: '支持短信、邮件、企业微信、钉钉、站内消息等发送方式' },
            { desc: '可灵活定义消息模板、自动提醒规则' },
            { desc: '消息内容可设置短链接，点击即可办理业务' },
            { desc: '消息中心运行情况的实时监控和统计分析' }
          ],
          src: require('@/assets/product/productPt/notyCenter.png')
        }

      ]
    }
  },
  methods: {
    getHeight () {
      if (window.innerWidth <= 800) {
        this.$router.push('/moProduct/moPT')
      }
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  },
  created () {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  }
}
</script>

<style scoped lang='scss'>
.PTContainer{
  position: relative;
  top: 0;
  left: 0;
  min-width: 1200px;
  height: 100vh;
  overflow: hidden;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  object-fit: cover;
}
li {
  list-style: none;
}
.line {
  display: inline-block;
  width: 40px;
  height: 6px;
  background: #d70c0c;
  border-radius: 3px;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.descH4 {
  font-size: 36px;
  font-weight: 500;
  color: #222222;
  padding: 60px 0 20px;
}
.channelShip {
  padding-top: 80px;
}
.banner {
  width: 100%;
  height: 460px;
  background: url("~@/assets/product/productPt/banner.png") center no-repeat;
    background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.banner button {
  width: 200px;
  height: 60px;
  background: linear-gradient(135deg, #ff8484 0%, #c61f1f 100%);
  border-radius: 34px;
  font-size: 16px;
  color: #fff;
  border: 0;
}
.banner button:hover {
  box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
  cursor: pointer;
}

.ptDesc{
  width: 100%;
height: 970px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}
.ptDescContent{
  width: 1200px;
  height: 300px;
  display: flex;
  align-items: center;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.3);
  margin: 40px 0;
  border-radius: 10px;
}
.ptDescLeft{
  position: relative;
  top: 0;
  left: 0;
  width: 450px;
  height: 100%;
  border-radius: 10px 0 0 10px;
}
.ptDescLeftMask{
  width: 450px;
  height: 100%;
  border-radius: 10px 0 0 10px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(45, 45, 48, 0.15);
}
.ptDescRigth{
  width: 750px;
  height: 100%;
  background: url('~@/assets/product/productPt/ptBj.jpg') center no-repeat;
  background-size: 100% 100%;
  border-radius: 0px 10px 10px 0px;
  padding: 50px 80px;
  text-shadow: 0px 8px 24px rgba(0,0,0,0.15);
  font-size: 18px;
  color: #222;
  font-weight: 400;
  line-height: 40px;
}

.valueContent{
  display: flex;
  flex-wrap: wrap;
  width: 1200px;
  padding-top: 40px;
}
.valueItem{
  position: relative;
  top: 0;
  left: 0;
  width: 580px;
  height: 156px;
  margin-right: 40px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 40px;
  padding: 40px;
  cursor: pointer;
}
.valueItemMask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.valueItem:nth-child(2n){
  margin-right: 0px;
}
.valueLeftRed{
  width: 60px;
}
.valueLeftHover{
  width: 60px;
  display: none;
}
.valueRight{
  margin-left: 24px;
  .valueTitle{
    font-size: 24px;
    font-weight: 500;
    color: #222222;
    margin-bottom: 16px;
  }
  .valueText{
    font-size: 16px;
    font-weight: 400;
    color: #444444;
    line-height: 24px;
  }
}
.valueItem:hover{
  box-shadow: 0px 8px 20px 0px rgba(173,0,0,0.3);
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  .valueItemMask{
    display: block;
    background: url('~@/assets/hoverBg.png') center no-repeat;
    background-position: top left;
  }
  .valueTitle{
    color: #fff;
  }
  .valueText{
    color: #fff;
  }
  .valueLeftRed{
    display: none;
  }
  .valueLeftHover{
    display:block ;
  }
}

.mainAble{
width: 100%;
height: 900px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}
::v-deep .mainFunctionCard .CareContent{
    padding: 50px 0 30px;
}
::v-deep .mainFunctionCard .CareContent .right img{
width: 560px;
}
::v-deep .mainFunctionCard .CareContent .fontContent p{
    margin-bottom: 10px;
}

</style>
